// import "../styles/SearchDetailWidget.module.css";
import "../static/css/globals.css";
import "@jetprivilege/web/dist/css/theme.css";
import "react-day-picker/lib/style.css";


function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}

export default MyApp;
